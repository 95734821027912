import { Dropdown, FormikInput, Stack, TextField } from 'gantri-components';
import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { StatusItem } from '../../../../../machine/sections/general-section/status-item';
import { machineTypeOptions } from '../../../../machine.constants';
import { machineStatusOptions } from '../../../../../machine/sections/general-section/general-section.constant';
import { machineStatuses } from '../../../../../../constants/machines';
import { AddOrEditMachineModalContentPropsDef } from './add-or-edit-machine-modal-content.types';
import { AddOrEditMachineFormDef } from '../../add-or-edit-machine-modal.types';

export const AddOrEditMachineModalContent = (
  props: AddOrEditMachineModalContentPropsDef,
) => {
  const { isEditing } = props;

  const statusItems = useMemo(() => {
    return isEditing
      ? machineStatusOptions
      : machineStatusOptions.filter(({ value }) => {
          return value !== machineStatuses.archived;
        });
  }, [isEditing]);

  const { setFieldValue } = useFormikContext<AddOrEditMachineFormDef>();

  return (
    <Stack gap="2x">
      <FormikInput
        Field={<TextField labelText="Name" placeholder="Input name" required />}
        name="name"
      />

      <FormikInput
        Field={<TextField labelText="Bay" placeholder="Input bay" required />}
        name="bay"
      />

      <FormikInput
        Field={
          <Dropdown
            items={machineTypeOptions}
            labelText="Type"
            placeholder="Select type"
            required
          />
        }
        name="type"
      />

      <FormikInput
        Field={
          <TextField labelText="Location" placeholder="Location" required />
        }
        name="location"
      />

      <FormikInput
        Field={
          <Dropdown
            items={statusItems}
            labelText="Status"
            maxHeight={400}
            placeholder="Please select"
            renderItem={(option) => {
              return <StatusItem showDescription status={option?.label} />;
            }}
            renderPlaceholder={(option) => {
              return (
                <StatusItem
                  status={option?.label}
                  style={{ paddingLeft: '.8rem' }}
                />
              );
            }}
            required
          />
        }
        name="status"
      />

      <FormikInput
        Field={
          <TextField
            labelText="Serial Number"
            placeholder="Input serial number"
          />
        }
        name="serialNumber"
      />

      <FormikInput
        Field={
          <TextField labelText="MAC Address" placeholder="Input MAC address" />
        }
        name="macAddress"
        onTextChange={async (value) => {
          const separator = ':';
          const separatorPattern = new RegExp(separator, 'g');
          const purifiedValue = value.replace(separatorPattern, '');
          const every2Chars = purifiedValue.match(/.{1,2}/g);
          const formattedValue = every2Chars.join(separator);

          await setFieldValue('macAddress', formattedValue);
        }}
      />

      <FormikInput
        labelText="IP Address"
        name="ipAddress"
        placeholder="Input IP address"
        onTextChange={async (value) => {
          await setFieldValue('ipAddress', value.trim());
        }}
      />

      <FormikInput
        labelText="Password"
        name="password"
        placeholder="Input password"
        onTextChange={async (value) => {
          await setFieldValue('password', value.trim());
        }}
      />
    </Stack>
  );
};

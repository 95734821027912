import * as yup from 'yup';
import { machineStatuses, machineTypes } from '../../constants/machines';
import { MachineRecord } from '../machines/machines.types';

export const machineSchemaData = {
  ipAddress: {
    message: 'Input is not a valid IP address format',
    pattern: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/g,
  },
  macAddress: {
    message: 'Input must be 12 characters',
    pattern: /^(?:[a-zA-Z0-9]{2}:){5}[a-zA-Z0-9]{2}$/g,
  },
  serialNumber: {
    message: 'Input must be 15 characters',
    pattern: /^[a-zA-Z0-9]{15}$/g,
  },
} satisfies Record<string, { message: string; pattern: RegExp }>;

const requiredText = 'Required';

export interface EditMachineDataFormDef
  extends Pick<
    MachineRecord,
    | 'bay'
    | 'location'
    | 'macAddress'
    | 'ipAddress'
    | 'password'
    | 'name'
    | 'serialNumber'
    | 'status'
    | 'type'
  > {}

export const machineFieldSchemas = {
  bay: yup.string().required(requiredText),
  ipAddress: yup
    .string()
    .trim()
    .matches(
      machineSchemaData.ipAddress.pattern,
      machineSchemaData.ipAddress.message,
    )
    .required(requiredText),
  location: yup.string(),
  macAddress: yup
    .string()
    .trim()
    .length(
      // offset for separator characters
      17,
      machineSchemaData.macAddress.message,
    )
    .matches(
      machineSchemaData.macAddress.pattern,
      machineSchemaData.macAddress.message,
    ),
  name: yup.string().required(requiredText),
  password: yup.string(),
  serialNumber: yup
    .string()
    .trim()
    .length(15, machineSchemaData.serialNumber.message)
    .matches(
      machineSchemaData.serialNumber.pattern,
      machineSchemaData.serialNumber.message,
    )
    .required(requiredText),
  status: yup
    .mixed()
    .oneOf(Object.values(machineStatuses))
    .required(requiredText),
  type: yup.mixed().oneOf(Object.values(machineTypes)).required(requiredText),
} as const;

export const machineSchema: yup.SchemaOf<EditMachineDataFormDef> = yup
  .object()
  .shape({
    bay: machineFieldSchemas.bay,
    ipAddress: machineFieldSchemas.ipAddress,
    location: machineFieldSchemas.location,
    macAddress: machineFieldSchemas.macAddress,
    name: machineFieldSchemas.name,
    password: machineFieldSchemas.password,
    serialNumber: machineFieldSchemas.serialNumber,
    status: machineFieldSchemas.status,
    type: machineFieldSchemas.type,
  });

import React, { FC } from 'react';
import { FormikInput, Grid } from 'gantri-components';
import { useFormikContext } from 'formik';
import { FormItem } from '../../../../inventory/components/form-item';
import { OverviewMachine } from '../../../../../api/machines/routes/get-overview';
import { formatDate } from '../../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../../constants/dates';
import { ResetJobCount } from '../reset-job-count';
import { EditMachineDataFormDef } from '../../../machine.schema';

export const OverviewFormSection: FC<{
  currentMachine: OverviewMachine;
  isEditModeActive?: boolean;
}> = ({ currentMachine, isEditModeActive }) => {
  const { setFieldValue } = useFormikContext<EditMachineDataFormDef>();

  return (
    <Grid
      columns="1fr"
      gap="1.2rem"
      maxWidth={isEditModeActive ? '60rem' : 'unset'}
    >
      <FormItem
        isEditMode={isEditModeActive}
        label="Machine Name"
        orientation="row"
        viewElement={currentMachine.name}
      >
        <FormikInput name="name" placeholder="Input name" required />
      </FormItem>

      <FormItem
        isEditMode={isEditModeActive}
        label="Serial Number"
        orientation="row"
        viewElement={currentMachine.serialNumber || '-'}
      >
        <FormikInput name="serialNumber" placeholder="Input serial number" />
      </FormItem>

      <FormItem
        isEditMode={isEditModeActive}
        label="MAC Address"
        orientation="row"
        viewElement={currentMachine.macAddress || '-'}
      >
        <FormikInput
          name="macAddress"
          placeholder="Input MAC address"
          onTextChange={async (value) => {
            const separator = ':';
            const separatorPattern = new RegExp(separator, 'g');
            const purifiedValue = value.replace(separatorPattern, '');
            const every2Chars = purifiedValue.match(/.{1,2}/g);
            const formattedValue = every2Chars.join(separator);

            await setFieldValue('macAddress', formattedValue);
          }}
        />
      </FormItem>

      <FormItem
        isEditMode={isEditModeActive}
        label="IP Address"
        orientation="row"
        viewElement={currentMachine.ipAddress || '-'}
      >
        <FormikInput
          name="ipAddress"
          placeholder="Input IP address"
          onTextChange={async (value) => {
            await setFieldValue('ipAddress', value.trim());
          }}
        />
      </FormItem>

      <FormItem
        isEditMode={isEditModeActive}
        label="Password"
        orientation="row"
        viewElement={currentMachine.password || '-'}
      >
        <FormikInput
          name="password"
          placeholder="Input password"
          onTextChange={async (value) => {
            await setFieldValue('password', value.trim());
          }}
        />
      </FormItem>

      <FormItem
        label="Created"
        orientation="row"
        viewElement={formatDate(currentMachine.createdAt, LONG_FORMAT)}
      />

      <FormItem
        label="Current Jobs"
        orientation="row"
        viewElement={String(currentMachine.currentJobsCount)}
      />

      <FormItem
        label="Past Jobs"
        orientation="row"
        viewElement={String(currentMachine.pastJobsCount)}
      />

      <FormItem
        isEditMode={isEditModeActive}
        label="Location"
        orientation="row"
        viewElement={currentMachine.location}
      >
        <FormikInput name="location" />
      </FormItem>

      <FormItem
        label="Prints on Build Plate"
        orientation="row"
        viewElement={<ResetJobCount currentMachine={currentMachine} />}
      />

      <FormItem
        label="Last repair"
        orientation="row"
        viewElement={
          formatDate(currentMachine.lastRepairInfo?.date, LONG_FORMAT) || '-'
        }
      />
    </Grid>
  );
};
